var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "device-child-list-component" },
    [
      _c(
        "div",
        { staticClass: "page-table-search page-table-search-small" },
        [
          _c("a-input-search", {
            staticClass: "page-table-search__input",
            attrs: { placeholder: "请输入设备名称", "allow-clear": "" },
            model: {
              value: _vm.queryModel.name,
              callback: function($$v) {
                _vm.$set(_vm.queryModel, "name", $$v)
              },
              expression: "queryModel.name"
            }
          }),
          _c(
            "jtl-button",
            {
              staticClass: "pull-right",
              attrs: { type: "primary" },
              on: { click: _vm.addDeviceClick }
            },
            [_vm._v("关联子设备")]
          )
        ],
        1
      ),
      _c("a-table", {
        attrs: {
          loading: _vm.listLoading,
          columns: _vm.tableColumns,
          "data-source": _vm.ListData,
          pagination: _vm.Pagination,
          scroll: _vm.tableScroll,
          "row-key": "id",
          size: _vm.TableSize
        },
        on: { change: _vm.tableChange },
        scopedSlots: _vm._u([
          {
            key: "name",
            fn: function(text) {
              return [_vm._v(" " + _vm._s(text) + " ")]
            }
          },
          {
            key: "deviceStatus",
            fn: function(text, record) {
              return [
                _c("span", {
                  class: "status-circle " + record.Online + "-circle"
                }),
                _vm._v(
                  " " +
                    _vm._s(_vm._f("deviceStatusFilter")(record.Online)) +
                    " / " +
                    _vm._s(record.Active) +
                    " "
                )
              ]
            }
          },
          {
            key: "action",
            fn: function(text, record) {
              return [
                _c(
                  "a",
                  {
                    on: {
                      click: function($event) {
                        return _vm.detailClick(record.id)
                      }
                    }
                  },
                  [_vm._v("查看")]
                ),
                _c("a-divider", { attrs: { type: "vertical" } }),
                _c(
                  "a-popconfirm",
                  {
                    attrs: {
                      title: "确认解除关联?",
                      "ok-text": "确认",
                      "cancel-text": "取消"
                    },
                    on: {
                      confirm: function($event) {
                        return _vm.unlinkClick(record.id)
                      }
                    }
                  },
                  [_c("a", [_vm._v("解除关联")])]
                )
              ]
            }
          }
        ])
      }),
      _c("device-link-child-dialog", {
        ref: "deviceLinkChildDialog",
        on: { dialogOK: _vm.formChildDialogOK }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }