

















import { Component } from 'vue-property-decorator';
import FormDialogComponent from '@/mixins/form-dialog-component';
import { ICRUDQ } from '@/model/interface';
import { ViewModeType } from '@/model/enum';
import DeviceService from '@/service/device';
import { DeviceImportExcelEntityModel } from '@/entity-model/device-entity';

@Component
export default class ImportExcelComponent extends FormDialogComponent<DeviceImportExcelEntityModel> {
    dialogOpen(importModel: DeviceImportExcelEntityModel, service: ICRUDQ<any, any>, viewMode: ViewModeType, needGetDetail: boolean = false): void {
        this.dialogVisible = true;
        this.viewMode = ViewModeType.NEW;
        this.dialogTitle = '批量导入设备';
        this.$nextTick(() => {
            if (this.jtlForm) {
                this.jtlForm.initForm(importModel, viewMode);
            }
        });
    }

    dialogOK(): any {
        if (!this.jtlForm) {
            return;
        }
        this.startFullScreenLoading('正在导入，请耐心等待......');
        return new Promise((resolve, reject) => {
            this.jtlForm.submitForm().then((ret) => {
                DeviceService.importExcel(this.jtlForm.formModel).then(res => {
                    this.showMessageSuccess('批量导入设备成功');
                    this.dialogClose();
                    this.$emit('dialogOK');
                    resolve(null);
                }).catch(err => {
                    reject();
                    throw err;
                });
            }).catch(err => {
                reject();
                throw err;
            });
        }).finally(() => {
            this.stopFullScreenLoading();
        });
    }
}
