





































import { Component, Prop } from 'vue-property-decorator';
import TableDialogFormComponent from '@/mixins/table-dialog-form-component';
import { DeviceMethodEntityModel, DeviceMethodQueryModel } from '@/entity-model/device-method-entity';
import DeviceMethodService from '@/service/device-method';

@Component
export default class DeviceMethodListComponent extends TableDialogFormComponent<DeviceMethodEntityModel, DeviceMethodQueryModel> {
    @Prop()
    deviceId: string;

    created() {
        this.initTable({
            service: DeviceMethodService,
            queryModel: new DeviceMethodQueryModel(this.deviceId),
            tableColumns: DeviceMethodEntityModel.getTableColumns()
        });
        this.getList();
    }
}

