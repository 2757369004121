
































































































import { Component, Mixins } from 'vue-property-decorator';
import TableDialogFormComponent from '@/mixins/table-dialog-form-component';
import DrawerComponent from '@/mixins/drawer-component';
import { DeviceEntityModel } from '@/entity-model/device-entity';
import { AttributeEntityType, ThingsModelType, ViewModeType } from '@/model/enum';
import DeviceChildListComponent from './child-list.vue';
import DeviceService from '@/service/device';
import DeviceThingsIndexComponent from './device-things-index.vue';
import DeviceAttrComponent from './device-attr.vue';
import FormDialogComponent from '@/mixins/form-dialog-component';
import DeviceMethodComponent from './method/device-method.vue';
import DeviceProtocolTableComponent from './device-protocol-table.vue';
import { copyToPasteboard } from '@/utils/base_util';

@Component({
    components: {
        'device-things-index-component': DeviceThingsIndexComponent,
        'device-child-list-component': DeviceChildListComponent,
        'device-attr-component': DeviceAttrComponent,
        'device-method-component': DeviceMethodComponent,
        'device-protocol-table-component': DeviceProtocolTableComponent
    }
})
export default class DeviceDetailDrawer extends Mixins(TableDialogFormComponent, DrawerComponent) {
    deviceId: string = null;
    AttributeEntityType = AttributeEntityType;
    ThingsModelType = ThingsModelType;
    deviceModel: DeviceEntityModel = new DeviceEntityModel();
    serverAttrs: any = null;
    tabIndex: string = '1';
    saveBtnDisabled: boolean = true;
    clientAttrs:any = null;

    get IsShowDeviceAttr() {
        if (this.deviceModel.protocol && _.findIndex(this.serverAttrs, (item: any) => item.key === 'Param') === -1) {
            return false;
        }
        return true;
    }

    drawerShow(model: DeviceEntityModel) {
        this.clientAttrs = null;
        this.drawerOpen(model.id, model.displayName);
        this.init(model.id);
    }

    init(deviceId: string) {
        this.deviceId = deviceId;
        this.startFullScreenLoading();
        DeviceService.retrieve(deviceId)
            .then(res => {
                this.deviceModel = res.deviceModel;
                this.serverAttrs = res.serverAttrs;
                // 默认给每个设备协议系数值为1
                if (res.clientAttrs) {
                    _.forEach(res.clientAttrs, item => {
                        if (!item.ratio) item.ratio = 1;
                    });
                }
                this.clientAttrs = res.clientAttrs;

                // let serverAttrs = _.filter(res.serverAttrs, item => (item.key !== 'Param'));
                // let clientParam = _.filter(res.serverAttrs, item => (item.key === 'Param'));
                // let clientParamFilter = [];
                // // 为了暴露结构化List判断clientParam结构类型是string和text
                // if (clientParam && clientParam.length > 0) {
                //     clientParamFilter = _.filter(clientParam, item => item.definition['type'] === 'string' || item.definition['type'] === 'text');
                // }
                // if (this.deviceModel.protocol && clientParam.length > 0) { // 如果有协议，且有param，且结构类型是text，string，读取时按协议解析
                //     this.serverAttrs = serverAttrs;
                //     this.clientParam = clientParamFilter;
                //     this.clientAttrs = clientAttrs;
                // } else {
                //     this.serverAttrs = res.serverAttrs;
                // }
            })
            .catch(error => {
                this.deviceModel = new DeviceEntityModel();
            })
            .finally(() => {
                this.stopFullScreenLoading();
            });
    }
    reload() {
        // this.tabIndex = '1';
        this.init(this.deviceId);
    }
    copyCodeClick() {
        if (!this.deviceModel.accessToken) {
            this.showMessageWarning('设备还没有令牌');
            return;
        }
        if (copyToPasteboard(this.deviceModel.accessToken)) {
            this.showMessageSuccess('设备令牌已经复制到剪切板里了');
        }
    }
    getSaveBtnStatus(status) {
        this.saveBtnDisabled = status;
    }
    deviceAttrClick() {
        (this.$refs.deviceAttrComponent as DeviceAttrComponent).dialogOpen();
    }
    editClick() {
        (this.$refs.formDialog as FormDialogComponent<DeviceEntityModel>).dialogOpen(this.deviceModel, DeviceService, ViewModeType.UPDATE);
    }
    getAttributeValue(attribute: any) {
        if (_.get(attribute, 'definition.type') === ThingsModelType.PASSWORD) {
            return '●'.repeat((this.getAttributeValueText(attribute) || '').length);
        }
        return this.getAttributeValueText(attribute);
    }
    getAttributeValueText(attribute: any) {
        if (_.get(attribute, 'definition.type') === ThingsModelType.ENUM) {
            return _.get(attribute, `definition.specs.${attribute.value}`) || attribute.value;
        } else if (_.get(attribute, 'definition.type') === ThingsModelType.BOOLEAN) {
            return _.get(attribute, `definition.specs.${attribute.value ? 1 : 0}`) || attribute.value;
        }
        return attribute.value || _.get(attribute, 'definition.value');
    }
    drawerClose() {
        this.drawerVisible = false;
        this.deviceId = null;
    }
}
