























import { Component, Prop } from 'vue-property-decorator';
import TableDialogFormComponent from '@/mixins/table-dialog-form-component';
import { DeviceAttributeEntityModel, DeviceAttributeQueryModel } from '@/entity-model/device-attribute-entity';
import DeviceAttributeService from '@/service/device-attribute';
import DeviceAttributeDataComponent from './device-attribute-data.vue';

@Component({
    components: {
        'device-attribute-data-dialog': DeviceAttributeDataComponent
    }
})
export default class DeviceAttributeListComponent extends TableDialogFormComponent<DeviceAttributeEntityModel, DeviceAttributeQueryModel> {
    DeviceAttributeEntityModel = DeviceAttributeEntityModel;
    @Prop()
    deviceId: string;
    timeInterval: NodeJS.Timeout;

    created() {
        this.initTable({
            service: DeviceAttributeService,
            queryModel: new DeviceAttributeQueryModel(this.deviceId),
            tableColumns: DeviceAttributeEntityModel.getTableColumns()
        });
        this.getList();
    }

    viewDataClick(model: any) {
        this.viewClick(model);
    }
    switchOnChange(val) {
        if (val) {
            this.timeInterval = setInterval(() => {
                this.getList();
            }, 5000);
            this.getList();
        } else {
            clearInterval(this.timeInterval);
        }
    }
}

