
















import { Component, Prop } from 'vue-property-decorator';
import { BaseComponent } from '@/mixins/base-component';
import DeviceMethodService from '@/service/device-method';
import { DeviceMethodEntityModel } from '@/entity-model/device-method-entity';
import { ViewModeType, ThingsModelType } from '@/model/enum';
import { FormControlType } from '@/model/form-control';

@Component
export default class DeviceMethodComponent extends BaseComponent {
    @Prop()
    deviceId: string;
    methodList: Array<DeviceMethodEntityModel> = null;

    mounted() {
        DeviceMethodService.getAllMethod(this.deviceId).then(res => {
            this.methodList = res;
            this.$nextTick(() => {
                this.initInputParamForm();
            });
        });
    }

    initInputParamForm() {
        _.forEach(this.methodList, (item, index) => {
            const controlList: any = _.map(item.inputData, inputItem => {
                const paramType = inputItem.thingsModelTypeModel.thingsModelType;
                const controlItem: any = {
                    key: inputItem.identifier,
                    value: inputItem.thingsModelTypeModel.value,
                    label: inputItem.name,
                    type: null,
                    layout: { labelCol: 6, wrapperCol: 15, width: '100%' }
                };
                switch (paramType) {
                    case ThingsModelType.INT:
                    case ThingsModelType.DOUBLE:
                    case ThingsModelType.FLOAT:
                        controlItem.type = FormControlType.NUMBER;
                        controlItem.inputType = 'number';
                        controlItem.min = inputItem.thingsModelTypeModel.dataMin;
                        controlItem.max = inputItem.thingsModelTypeModel.dataMax;
                        controlItem.step = inputItem.thingsModelTypeModel.step;
                        controlItem.placeholder = `请输入${controlItem.label}`;
                        break;
                    case ThingsModelType.BOOLEAN:
                        controlItem.type = FormControlType.SWITCH;
                        controlItem.checkText = inputItem.thingsModelTypeModel.dataTrueName;
                        controlItem.uncheckText = inputItem.thingsModelTypeModel.dataFalseName;
                        controlItem.value = false;
                        break;
                    case ThingsModelType.ENUM:
                    {
                        controlItem.type = FormControlType.SELECT;
                        const specs = inputItem.thingsModelTypeModel.enumList;
                        controlItem.options = _.map(_.keysIn(specs), item => {
                            return { name: specs[item], value: item };
                        });
                        controlItem.placeholder = `请选择${controlItem.label}`;
                        break;
                    }
                    case ThingsModelType.JSON:
                        controlItem.type = FormControlType.TEXT_AREA;
                        controlItem.placeholder = `请输入${controlItem.label}`;
                        controlItem.value = JSON.stringify(controlItem.value);
                        break;
                    case ThingsModelType.STRING:
                    case ThingsModelType.TEXT:
                    default:
                        controlItem.type = FormControlType.TEXT;
                        controlItem.value = '';
                        break;
                }
                return controlItem;
            });
            this.$refs.methodInputParam[index].initFormByControls(controlList, ViewModeType.UPDATE);
        });
    }

    rpcClick(param: {methodModel: DeviceMethodEntityModel, index: number}) {
        const { methodModel, index } = param;
        return new Promise((resolve, reject) => {
            this.$refs.methodInputParam[index].submitForm().then(res => {
                const rpcParam = {
                    entityId: this.deviceId,
                    invokeInfo: {
                        method: methodModel.identifier,
                        params: {}
                    }
                };
                _.forEach(methodModel.inputData, item => {
                    rpcParam.invokeInfo.params[item.identifier] = this.$refs.methodInputParam[index].formModel[item.identifier];
                });
                DeviceMethodService.rpc(rpcParam).then(res => {
                    this.showMessageSuccess('执行成功');
                    resolve(null);
                }).catch(() => {
                    reject();
                });
            }).catch(() => {
                reject();
            });
        });
    }
}
