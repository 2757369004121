var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-drawer",
    {
      attrs: {
        title: _vm.drawerTitle,
        placement: _vm.placement,
        width: _vm.width,
        closable: _vm.closable,
        visible: _vm.drawerVisible,
        "destroy-on-close": true
      },
      on: { close: _vm.drawerClose }
    },
    [
      _c(
        "div",
        { staticClass: "device-detail-component table-component" },
        [
          _c(
            "a-tabs",
            {
              attrs: { type: "card" },
              model: {
                value: _vm.tabIndex,
                callback: function($$v) {
                  _vm.tabIndex = $$v
                },
                expression: "tabIndex"
              }
            },
            [
              _c(
                "a-tab-pane",
                { key: "1", attrs: { tab: "设备信息" } },
                [
                  _c(
                    "a-descriptions",
                    { staticClass: "tab-margin-16", attrs: { bordered: "" } },
                    [
                      _c(
                        "div",
                        { attrs: { slot: "title" }, slot: "title" },
                        [
                          _c("span", [_vm._v("基本信息")]),
                          _c(
                            "jtl-button",
                            {
                              staticClass: "description-title-button",
                              attrs: { type: "primary" },
                              on: { click: _vm.editClick }
                            },
                            [_vm._v("编辑")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-descriptions-item",
                        { attrs: { label: "设备编号" } },
                        [_vm._v(" " + _vm._s(_vm.deviceModel.code) + " ")]
                      ),
                      _c(
                        "a-descriptions-item",
                        { attrs: { label: "设备令牌" } },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.deviceModel.accessToken) + " "
                          ),
                          _c(
                            "span",
                            {
                              staticClass: "jtl-link",
                              on: { click: _vm.copyCodeClick }
                            },
                            [_vm._v("复制")]
                          )
                        ]
                      ),
                      _c(
                        "a-descriptions-item",
                        { attrs: { label: "创建时间" } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("dateFormat")(
                                  _vm.deviceModel.createdTime
                                )
                              ) +
                              " "
                          )
                        ]
                      ),
                      _c(
                        "a-descriptions-item",
                        { attrs: { label: "设备名称" } },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.deviceModel.displayName) + " "
                          )
                        ]
                      ),
                      _c(
                        "a-descriptions-item",
                        { attrs: { label: "安装位置" } },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.deviceModel.positionName) + " "
                          )
                        ]
                      ),
                      _c(
                        "a-descriptions-item",
                        { attrs: { label: "最后上线时间" } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("dateFormat")(
                                  _vm.deviceModel.lastActivityTime
                                )
                              ) +
                              " "
                          )
                        ]
                      ),
                      _c(
                        "a-descriptions-item",
                        { attrs: { label: "当前状态" } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("deviceStatusFilter")(
                                  _vm.deviceModel.Online
                                )
                              ) +
                              " "
                          )
                        ]
                      ),
                      _c(
                        "a-descriptions-item",
                        { attrs: { label: "所属产品" } },
                        [_vm._v(" " + _vm._s(_vm.deviceModel.typeName) + " ")]
                      ),
                      _c(
                        "a-descriptions-item",
                        { attrs: { label: "设备ID" } },
                        [_vm._v(" " + _vm._s(_vm.deviceId) + " ")]
                      ),
                      _c(
                        "a-descriptions-item",
                        { attrs: { label: "所属网关", span: 3 } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("text")(_vm.deviceModel.parentPath)
                              ) +
                              " "
                          )
                        ]
                      ),
                      _c(
                        "a-descriptions-item",
                        { attrs: { label: "设备分组", span: 3 } },
                        [_vm._v(" " + _vm._s(_vm.deviceModel.groupName) + " ")]
                      ),
                      _c(
                        "a-descriptions-item",
                        { attrs: { label: "备注名称", span: 3 } },
                        [_vm._v(" " + _vm._s(_vm.deviceModel.remark) + " ")]
                      )
                    ],
                    1
                  ),
                  _c("br"),
                  _vm.IsShowDeviceAttr
                    ? _c(
                        "a-descriptions",
                        {
                          staticClass: "tab-margin-16",
                          attrs: { bordered: "" }
                        },
                        [
                          _c(
                            "div",
                            { attrs: { slot: "title" }, slot: "title" },
                            [
                              _c("span", [_vm._v("服务端属性")]),
                              _c(
                                "jtl-button",
                                {
                                  staticClass: "description-title-button",
                                  attrs: { type: "primary" },
                                  on: { click: _vm.deviceAttrClick }
                                },
                                [_vm._v("配置属性")]
                              )
                            ],
                            1
                          ),
                          _vm._l(_vm.serverAttrs, function(item) {
                            return _c(
                              "a-descriptions-item",
                              { key: item.key },
                              [
                                _c(
                                  "div",
                                  { attrs: { slot: "label" }, slot: "label" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "attribute-label" },
                                      [_vm._v(_vm._s(item.name))]
                                    )
                                  ]
                                ),
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.getAttributeValue(item)) +
                                    " "
                                )
                              ]
                            )
                          })
                        ],
                        2
                      )
                    : _c(
                        "div",
                        [
                          _c(
                            "span",
                            {
                              staticClass: "ant-descriptions-title",
                              staticStyle: { "margin-left": "16px" }
                            },
                            [_vm._v("服务端属性")]
                          ),
                          _c("a-empty", {
                            attrs: { description: "还没有服务端属性" }
                          })
                        ],
                        1
                      ),
                  _c("device-attr-component", {
                    ref: "deviceAttrComponent",
                    attrs: {
                      "entity-id": _vm.deviceId,
                      "server-attrs": _vm.serverAttrs,
                      "client-attrs": _vm.clientAttrs,
                      protocol: _vm.deviceModel.protocol,
                      "device-name": _vm.deviceModel.displayName
                    },
                    on: { dialogOK: _vm.reload }
                  })
                ],
                1
              ),
              _c(
                "a-tab-pane",
                { key: "2", attrs: { tab: "物模型数据" } },
                [
                  _vm.deviceId
                    ? _c("device-things-index-component", {
                        attrs: {
                          "device-id": _vm.deviceId,
                          "entity-type": _vm.AttributeEntityType.DEVICE
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _c(
                "a-tab-pane",
                { key: "3", attrs: { tab: "设备控制" } },
                [
                  _vm.deviceId
                    ? _c("device-method-component", {
                        attrs: { "device-id": _vm.deviceId }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("jtl-entity-dialog", {
        ref: "formDialog",
        on: { dialogOK: _vm.reload }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }