





































import { Component, Prop, Mixins } from 'vue-property-decorator';
import TableDialogFormComponent from '@/mixins/table-dialog-form-component';
import { DeviceEventEntityModel, DeviceEventQueryModel } from '@/entity-model/device-event-entity';
import DeviceEventService from '@/service/device-event';
import { eventLevelList } from '@/filter/event-level';

@Component
export default class DeviceEventListComponent extends Mixins(TableDialogFormComponent) {
    @Prop()
    deviceId: string;

    created() {
        // this.isAutoQuery = false;
        // this.timeSelect = 0;
        // this.timeRange = [
        //     moment().add(-1, 'month'),
        //     moment()
        // ];
        this.initTable({
            service: DeviceEventService,
            queryModel: new DeviceEventQueryModel(this.deviceId),
            tableColumns: DeviceEventEntityModel.getTableColumns()
        });
        this.getList();
    }
    get AllEventLevelOptions() {
        return this.getAllOptions(eventLevelList);
    }
    // selectTimeChange() {
    //     if (this.timeSelect > 0) {
    //         this.getData();
    //     }
    // }
    // selectTimeRangeChange() {
    //     if (this.timeRange) {
    //         this.getData();
    //     }
    // }
    // inputChange() {
    //     this.debounceGetList();
    // }
    // levelChange() {
    //     this.getList();
    // }
    // getData() {
    //     try {
    //         var { startTime, endTime } = this.getStartAndEndTime();
    //     } catch (err) {
    //         this.showMessageWarning(err.message);
    //         return;
    //     }
    //     (this.queryModel as DeviceEventQueryModel).startTime = startTime;
    //     (this.queryModel as DeviceEventQueryModel).endTime = endTime;
    //     this.getList();
    // }
}

