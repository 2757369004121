var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "device-attribute-list-component" },
    [
      _c(
        "div",
        { staticClass: "refresh-wrapper" },
        [
          _vm._v(" 实时刷新 "),
          _c("a-switch", {
            staticClass: "refresh-switch",
            on: { change: _vm.switchOnChange }
          })
        ],
        1
      ),
      _c("a-table", {
        attrs: {
          loading: _vm.listLoading,
          columns: _vm.tableColumns,
          "data-source": _vm.listData,
          pagination: false,
          scroll: _vm.tableScroll,
          "row-key": "identifier",
          size: _vm.TableSize
        },
        on: { change: _vm.tableChange },
        scopedSlots: _vm._u([
          {
            key: "action",
            fn: function(text, record) {
              return [
                _c(
                  "a",
                  {
                    on: {
                      click: function($event) {
                        return _vm.viewDataClick(record)
                      }
                    }
                  },
                  [_vm._v("查看数据")]
                )
              ]
            }
          }
        ])
      }),
      _c("device-attribute-data-dialog", {
        ref: "formDialog",
        attrs: { "device-id": _vm.deviceId }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }