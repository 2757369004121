import { BaseEntityModel } from '@/model/base-model';
import { QueryPageModel } from '@/model/query-model';
export class ProtocolEntityModel extends BaseEntityModel {
    static getModbusTableColumns() {
        return [
            {
                title: '名称',
                dataIndex: 'name'
            },
            {
                title: '标识符',
                dataIndex: 'identifier'
            },
            {
                title: '地址值',
                dataIndex: 'address',
                scopedSlots: { customRender: 'address' }
            }, {
                title: '系数',
                dataIndex: 'ratio',
                scopedSlots: { customRender: 'ratio' }
            }, {
                title: '长度',
                dataIndex: 'len',
                scopedSlots: { customRender: 'len' }
            }
        ];
    }
    static getBacnetTableColumns() {
        return [
            {
                title: '名称',
                dataIndex: 'name'
            },
            {
                title: '标识符',
                dataIndex: 'identifier'
            },
            {
                title: '变量名',
                dataIndex: 'variablename',
                scopedSlots: { customRender: 'variablename' }
            },
            {
                title: '地址值',
                dataIndex: 'address',
                scopedSlots: { customRender: 'address' }
            }, {
                title: '系数',
                dataIndex: 'ratio',
                scopedSlots: { customRender: 'ratio' }
            }
        ];
    }
    static getOPCWebTableColumns() {
        return [
            {
                title: '名称',
                dataIndex: 'name'
            },
            {
                title: '标识符',
                dataIndex: 'identifier'
            },
            {
                title: '变量名',
                dataIndex: 'attrstring',
                scopedSlots: { customRender: 'attrstring' }
            }, {
                title: '系数',
                dataIndex: 'ratio',
                scopedSlots: { customRender: 'ratio' }
            }
        ];
    }
}
export class ProtocolqueryModel extends QueryPageModel {
    name: string = undefined;

    toService() {
        const data: any = super.toService();
        return data;
    }
}
