
import { DeviceMethodEntityModel, DeviceMethodQueryModel } from '@/entity-model/device-method-entity';
import { ICRUDQ } from '@/model/interface';
import { get, post } from './request';

const URL_PATH = `${BI_BASE_REQUEST_PATH}/device`;

class DeviceMethodService implements ICRUDQ<DeviceMethodEntityModel, DeviceMethodQueryModel> {
    async create(model: DeviceMethodEntityModel):Promise<DeviceMethodEntityModel> {
        throw new Error('Not implement.');
    }

    async retrieve(modelId: string):Promise<DeviceMethodEntityModel> {
        throw new Error('Not implement.');
    }

    async update(model: DeviceMethodEntityModel):Promise<DeviceMethodEntityModel> {
        throw new Error('Not implement.');
    }

    async delete(model: DeviceMethodEntityModel):Promise<DeviceMethodEntityModel> {
        throw new Error('Not implement.');
    }

    async query(query?: DeviceMethodQueryModel, page?: number, limit?: number):Promise<any> {
        const url = `${BI_BASE_REQUEST_PATH}/methodhisto/list`;
        const params = Object.assign({ page, limit }, query?.toService());
        const res = await post(url, params);
        // res.items = _.map(res.items, item => item = new DeviceMethodEntityModel().toModel(item));
        return res;
    }

    async getAllMethod(deviceId: string):Promise<any> {
        const url = `${URL_PATH}/getMethods/${deviceId}`;
        const res = await get(url);
        return _.map(res, item => item = new DeviceMethodEntityModel().toModel(item));
    }

    async rpc(params: {entityId: string, invokeInfo: {method: string, params: any}}):Promise<any> {
        const url = `${URL_PATH}/rpc`;
        const res = await post(url, params);
        return res;
    }
}

export default new DeviceMethodService();
