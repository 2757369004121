

















import { Component } from 'vue-property-decorator';
import FormDialogComponent from '@/mixins/form-dialog-component';
import { FormControlType } from '@/model/form-control';
import { ICRUDQ } from '@/model/interface';
import { ViewModeType } from '@/model/enum';
import DeviceService from '@/service/device';
import CommonService from '@/service/common';
import { ExportTemplateEntityModel } from '@/entity-model/device-entity';

@Component
export default class DownloadExcelComponent extends FormDialogComponent<ExportTemplateEntityModel> {
    dialogOpen(exportModel: ExportTemplateEntityModel, service: ICRUDQ<any, any>, viewMode: ViewModeType, needGetDetail: boolean = false): void {
        this.dialogVisible = true;
        this.viewMode = ViewModeType.NEW;
        this.dialogTitle = '下载模板';
        this.$nextTick(() => {
            if (this.jtlForm) {
                this.jtlForm.initForm(exportModel, viewMode);
            }
        });
    }

    dialogOK(): any {
        if (!this.jtlForm) {
            return;
        }
        this.startFullScreenLoading('正在导出...');
        return new Promise((resolve, reject) => {
            this.jtlForm.submitForm().then((ret) => {
                DeviceService.exportExcelByDeviceTypeId(this.jtlForm.formModel.toService().deviceTypeId, this.jtlForm.formModel.type).then(res => {
                    this.showMessageSuccess('导出模板成功');
                    this.dialogClose();
                    resolve(null);
                }).catch(err => {
                    reject();
                    throw err;
                });
            }).catch(err => {
                reject();
                throw err;
            }).finally(() => {
                this.stopFullScreenLoading();
            });
        });
    }
}
