var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "table-component" }, [
    _c(
      "div",
      { staticClass: "page-body page-body-margin-left-16" },
      [
        _c(
          "a-row",
          [
            _c(
              "a-col",
              { attrs: { span: 24 } },
              [
                _c("a-table", {
                  attrs: {
                    loading: _vm.listLoading,
                    columns: _vm.TableColumns,
                    "data-source": _vm.clientAttrs,
                    pagination: false,
                    scroll: { y: "65vh" },
                    "row-key": "identifier",
                    size: _vm.TableSize
                  },
                  on: { change: _vm.tableChange },
                  scopedSlots: _vm._u([
                    {
                      key: "variablename",
                      fn: function(text, record) {
                        return [
                          _c(
                            "a-select",
                            {
                              attrs: {
                                "allow-clear": true,
                                placeholder: "请选择变量名"
                              },
                              model: {
                                value: record.variablename,
                                callback: function($$v) {
                                  _vm.$set(record, "variablename", $$v)
                                },
                                expression: "record.variablename"
                              }
                            },
                            _vm._l(_vm.protocolModelTypeList, function(option) {
                              return _c(
                                "a-select-option",
                                {
                                  key: option.value,
                                  attrs: {
                                    value: option.value,
                                    title: option.name
                                  }
                                },
                                [_vm._v(" " + _vm._s(option.name) + " ")]
                              )
                            }),
                            1
                          )
                        ]
                      }
                    },
                    {
                      key: "address",
                      fn: function(text, record) {
                        return [
                          _c("a-input", {
                            model: {
                              value: record.address,
                              callback: function($$v) {
                                _vm.$set(record, "address", $$v)
                              },
                              expression: "record.address"
                            }
                          })
                        ]
                      }
                    },
                    {
                      key: "attrstring",
                      fn: function(text, record) {
                        return [
                          _c("a-input", {
                            model: {
                              value: record.attrstring,
                              callback: function($$v) {
                                _vm.$set(record, "attrstring", $$v)
                              },
                              expression: "record.attrstring"
                            }
                          })
                        ]
                      }
                    },
                    {
                      key: "ratio",
                      fn: function(text, record) {
                        return [
                          _c("a-input", {
                            model: {
                              value: record.ratio,
                              callback: function($$v) {
                                _vm.$set(record, "ratio", $$v)
                              },
                              expression: "record.ratio"
                            }
                          })
                        ]
                      }
                    },
                    {
                      key: "len",
                      fn: function(text, record) {
                        return [
                          _c("a-input-number", {
                            model: {
                              value: record.len,
                              callback: function($$v) {
                                _vm.$set(record, "len", $$v)
                              },
                              expression: "record.len"
                            }
                          })
                        ]
                      }
                    }
                  ])
                })
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }