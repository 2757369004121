







































import { Component, Prop, Mixins } from 'vue-property-decorator';
import FormDialogComponent from '@/mixins/form-dialog-component';
import DeviceAttributeService from '@/service/device-attribute';
import { dateFormat, dateFromNow } from '@/filter/date-format';
import moment, { Moment } from 'moment';

@Component
export default class DeviceAttributeDataComponent extends FormDialogComponent<any> {
    @Prop()
    deviceId: string;
    identifier: string = null;
    listLoading: boolean = false;
    listData: Array<any> = null;
    tableColumns: Array<any>;
    viewType: string = 'table';

    startDate: Moment = null;
    startTime: Moment = null;
    endDate: Moment = null;
    endTime: Moment = null;

    created() {
        this.endDate = moment();
        this.endTime = moment().endOf('day');
        this.startDate = moment().add(-5, 'day');
        this.startTime = moment().add(-5, 'day').startOf('day');
        this.tableColumns = [
            {
                title: '序号',
                customRender: (text, record, index) => {
                    return index + 1;
                },
                width: 100
            },
            {
                title: '时间',
                dataIndex: 'ts',
                customRender: (text, record, index) => {
                    return `${dateFormat(text)} (${dateFromNow(text)})`;
                }
            },
            {
                title: '数值',
                dataIndex: 'value'
            }
        ];
    }
    // loadMore() {
    //     return this.getData(true);
    // }
    // timeChange() {
    //     this.getData();
    // }
    dialogOpen(model: any) {
        this.dialogVisible = true;
        this.identifier = model.identifier;
        this.getData();
    }
    getData(isLoadMore: boolean = false) {
        let startTime, endTime;
        try {
            // const timeObj = this.getStartAndEndTime();
            // startTime = _.get(timeObj, 'startTime');
            // if (isLoadMore) {
            //     let lastTs = _.get(_.last(this.listData), 'ts');
            //     if (lastTs) {
            //         startTime = lastTs + 1;
            //     }
            //     endTime = lastTs || _.get(timeObj, 'endTime');
            // } else {
            //     endTime = _.get(timeObj, 'endTime');
            // }
            startTime = this.startDate.set('hour', this.startTime.get('hour')).set('minute', this.startTime.get('minute')).set('second', 0).toDate().getTime();
            if (isLoadMore) {
                endTime = _.get(_.last(this.listData), 'ts');
                if (endTime) {
                    endTime -= 1;
                }
            }
            if (!endTime) {
                endTime = this.endDate.set('hour', this.endTime.get('hour')).set('minute', this.endTime.get('minute')).set('second', 0).add('minute', 1).toDate().getTime();
            }
        } catch (err) {
            this.showMessageWarning(err.message);
            return;
        }

        this.listLoading = true;
        return DeviceAttributeService.getAttributeValues(this.deviceId, this.identifier, startTime, endTime).then(res => {
            if (isLoadMore) {
                if (!this.listData) {
                    this.listData = [];
                }
                this.listData.push(...res);
            } else {
                this.listData = res;
            }
        }).finally(() => {
            this.listLoading = false;
        });
    }
}
