import { DeviceAttributeQueryModel } from './device-attribute-entity';
import { ThingsMethodEntityModel } from './things-method-entity';
import { Moment } from 'moment';
import { dateFormat } from '@/filter';

export class DeviceMethodEntityModel extends ThingsMethodEntityModel {
    static getTableColumns() {
        return [
            {
                title: '时间',
                dataIndex: 'createdTime',
                customRender: (text, record, index) => {
                    return dateFormat(record.lastUpdateTs);
                }
            },
            {
                title: '名称',
                dataIndex: 'name'
            },
            {
                title: '标识符',
                dataIndex: 'identifier'
            },
            {
                title: '输入参数',
                dataIndex: 'inputData',
                scopedSlots: { customRender: 'inputData' }
            },
            {
                title: '输出参数',
                dataIndex: 'outputData',
                scopedSlots: { customRender: 'outputData' }
            }
        ];
    }

    // toModel(json): any {
    //     super.toModel(json);
    //     // this.name = _.get(json, 'eventName');
    //     this.inputData = _.get(json, 'inputData');
    //     this.outputData = _.get(json, 'outputData');
    //     return this;
    // }
}

export class DeviceMethodQueryModel extends DeviceAttributeQueryModel {
    name: string = undefined;
    // startTime: Moment = undefined;
    // endTime: Moment = undefined;
    timeRange: Array<Moment> = undefined;
    // timeRange: Array<Moment> = [
    //     moment().add(-1, 'month'),
    //     moment()
    // ];

    toService(): any {
        if (this.timeRange && this.timeRange.length > 2) {
            return {
                params: {
                    entityId: this.deviceId,
                    name: this.name,
                    startTime: this.timeRange[0].startOf('day').toDate().getTime(),
                    endTime: this.timeRange[1].endOf('day').toDate().getTime()
                }
            };
        }
        return {
            params: {
                entityId: this.deviceId,
                name: this.name
            }
        };
    }
}
