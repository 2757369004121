















import { Component, Prop } from 'vue-property-decorator';
import { BaseComponent } from '@/mixins/base-component';
import DeviceAttributeListComponent from './attribute/device-attribute-list.vue';
import DeviceEventListComponent from './event/device-event-list.vue';
import DeviceMethodListComponent from './method/device-method-list.vue';
import { ThingsFunctionType } from '@/model/enum';

@Component({
    components: {
        'device-attribute-list-component': DeviceAttributeListComponent,
        'device-event-list-component': DeviceEventListComponent,
        'device-method-list-component': DeviceMethodListComponent
    }
})
export default class DeviceThingsIndexComponent extends BaseComponent {
    ThingsFunctionType = ThingsFunctionType;
    @Prop()
    deviceId: string;
    tabIndex: ThingsFunctionType = ThingsFunctionType.ATTRIBUTE;
}
