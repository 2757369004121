
import { DeviceAttributeEntityModel, DeviceAttributeQueryModel } from '@/entity-model/device-attribute-entity';
import { ICRUDQ } from '@/model/interface';
import { get, post } from './request';

const URL_PATH = `${BI_BASE_REQUEST_PATH}/device`;

class DeviceAttributeService implements ICRUDQ<DeviceAttributeEntityModel, DeviceAttributeQueryModel> {
    async create(model: DeviceAttributeEntityModel):Promise<DeviceAttributeEntityModel> {
        throw new Error('Not implement.');
    }

    async retrieve(modelId: string):Promise<DeviceAttributeEntityModel> {
        throw new Error('Not implement.');
    }

    async update(model: DeviceAttributeEntityModel):Promise<DeviceAttributeEntityModel> {
        throw new Error('Not implement.');
    }

    async delete(model: DeviceAttributeEntityModel):Promise<DeviceAttributeEntityModel> {
        throw new Error('Not implement.');
    }

    async query(query?: DeviceAttributeQueryModel):Promise<any> {
        const url = `${URL_PATH}/getLatest/${query?.toService()}`;
        const res = await get(url);
        return res;
        // return _.map(res, item => item = new DeviceAttributeEntityModel().toModel(item));
    }

    async getAttributeValues(entityId: string, identifier: string, startTime: number, endTime: number):Promise<any> {
        const url = `${URL_PATH}/listAttrTsHisto`;
        const params = {
            limit: JTL.CONSTANT.PAGE_SIZE * 5,
            params: {
                entityId,
                identifier,
                startTime,
                endTime
            }
        };
        const res = await post(url, params);
        return res;
    }
}

export default new DeviceAttributeService();
