import { BaseEntityModel } from '@/model/base-model';
import { QueryModel } from '@/model/query-model';
import { dateFormat, dateFromNow } from '@/filter/date-format';

export class DeviceAttributeEntityModel extends BaseEntityModel {
    static getTableColumns() {
        return [
            {
                title: '名称',
                dataIndex: 'name'
            },
            {
                title: '所属产品',
                dataIndex: 'deviceType'
            },
            {
                title: '数据类型',
                dataIndex: 'dataType',
                width: 100
            },
            {
                title: '最新值',
                dataIndex: 'value',
                width: 150,
                customRender: (text, record, index) => {
                    // 需要在底层做格式化
                    // return kilo(text);
                    return text;
                }
            },
            {
                title: '更新时间',
                dataIndex: 'lastUpdateTs',
                customRender: (text, record, index) => {
                    if (text) {
                        return `${dateFormat(text)} (${dateFromNow(text)})`;
                    }
                    return '-';
                },
                width: 300
            },
            {
                title: '操作',
                dataIndex: 'action',
                scopedSlots: { customRender: 'action' },
                width: 150
            }
        ];
    }
}

export class DeviceAttributeQueryModel extends QueryModel {
    deviceId: string = undefined;
    constructor(deviceId: string) {
        super();
        this.deviceId = deviceId;
    }

    toService() {
        return this.deviceId;
    }
}
