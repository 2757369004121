
import { DeviceEventEntityModel, DeviceEventQueryModel } from '@/entity-model/device-event-entity';
import { ICRUDQ } from '@/model/interface';
import { post } from './request';

class DeviceEventService implements ICRUDQ<DeviceEventEntityModel, DeviceEventQueryModel> {
    async create(model: DeviceEventEntityModel):Promise<DeviceEventEntityModel> {
        throw new Error('Not implement.');
    }

    async retrieve(modelId: string):Promise<DeviceEventEntityModel> {
        throw new Error('Not implement.');
    }

    async update(model: DeviceEventEntityModel):Promise<DeviceEventEntityModel> {
        throw new Error('Not implement.');
    }

    async delete(model: DeviceEventEntityModel):Promise<DeviceEventEntityModel> {
        throw new Error('Not implement.');
    }

    async query(query?: DeviceEventQueryModel, page?: number, limit?: number):Promise<any> {
        const url = `${BI_BASE_REQUEST_PATH}/event/listEventHisto`;
        const params = Object.assign({ page, limit }, query?.toService());
        const res = await post(url, params);
        res.items = _.map(res.items, item => item = new DeviceEventEntityModel().toModel(item));
        return res;
    }
}

export default new DeviceEventService();
