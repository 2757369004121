import { EventLevelType } from '@/model/enum';
import { ThingsEventEntityModel } from './things-event-entity';
import { eventLevelFilter } from '@/filter';
import { DeviceMethodQueryModel } from './device-method-entity';

export class DeviceEventEntityModel extends ThingsEventEntityModel {
    deviceType: string = undefined;

    static getTableColumns() {
        return [
            {
                title: '时间',
                dataIndex: 'createdTime'
            },
            {
                title: '名称',
                dataIndex: 'name'
            },
            {
                title: '产品类型',
                dataIndex: 'deviceType'
            },
            {
                title: '标识符',
                dataIndex: 'identifier'
            },
            {
                title: '事件类型',
                dataIndex: 'level',
                customRender: function(text, record, index) {
                    return eventLevelFilter(text);
                }
            },
            {
                title: '输出参数',
                dataIndex: 'outputData',
                scopedSlots: { customRender: 'outputData' }
            }
        ];
    }

    toModel(json): any {
        super.toModel(json);
        this.name = _.get(json, 'eventName');
        this.outputData = _.get(json, 'outputData');
        return this;
    }
}

export class DeviceEventQueryModel extends DeviceMethodQueryModel {
    level: EventLevelType | string = '';

    toService(): any {
        const data = super.toService();
        data.params.level = this.level;
        return data;
    }
}
