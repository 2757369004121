
























import { Component, Prop } from 'vue-property-decorator';
import FormDialogComponent from '@/mixins/form-dialog-component';
import { ViewModeType, ThingsFunctionType, AttributeType, ThingsModelType, ProtocolType } from '@/model/enum';
import ThingsModelTypeComponent from '@/components/jtl-form/things-model-type.vue';
import DeviceService from '@/service/device';
import { FormControlType } from '@/model/form-control';
import DeviceProtocolTableComponent from './device-protocol-table.vue';
import { NUMBER_FLOAT_REGEXP } from '@/model/regexp';

@Component({
    components: {
        'things-model-type-component': ThingsModelTypeComponent,
        'device-protocol-table-component': DeviceProtocolTableComponent
    }
})
export default class DeviceAttrComponent extends FormDialogComponent<any> {
    ThingsFunctionType = ThingsFunctionType.ATTRIBUTE;
    dialogVisible: boolean = false;
    @Prop()
    serverAttrs: Array<any>;
    @Prop()
    entityId: string;
    @Prop()
    clientAttrs:Array<any>;
    @Prop()
    protocol:ProtocolType;
    @Prop()
    deviceName:string;

    get ServerAttrs() {
        if (this.protocol) {
            return _.filter(this.serverAttrs, item => item.key !== 'Param');
        }
        return this.serverAttrs;
    }

    dialogOpen() {
        this.dialogVisible = true;
        this.$nextTick(() => {
            const controlList: any = _.map(this.ServerAttrs, item => {
                const attrType = _.get(item, 'definition.type');
                const controlItem: any = {
                    key: item.key,
                    value: _.get(item, 'value'),
                    label: _.get(item, 'name'),
                    type: null,
                    layout: { labelCol: 5, wrapperCol: 18, width: '100%' }
                };
                switch (attrType) {
                    case ThingsModelType.INT:
                    case ThingsModelType.FLOAT:
                    case ThingsModelType.DOUBLE:
                        controlItem.type = FormControlType.NUMBER;
                        controlItem.inputType = 'number';
                        controlItem.min = parseInt(_.get(item, 'definition.specs.min'));
                        controlItem.max = parseInt(_.get(item, 'definition.specs.max'));
                        controlItem.step = parseInt(_.get(item, 'definition.specs.step'));
                        controlItem.placeholder = `请输入${controlItem.label}`;
                        break;
                    case ThingsModelType.BOOLEAN:
                        controlItem.type = FormControlType.SWITCH;
                        controlItem.checkText = _.get(item, 'definition.specs.1');
                        controlItem.uncheckText = _.get(item, 'definition.specs.0');
                        break;
                    case ThingsModelType.ENUM:
                    {
                        controlItem.type = FormControlType.SELECT;
                        const specs = _.get(item, 'definition.specs');
                        controlItem.options = _.map(_.keysIn(specs), item => {
                            return { name: specs[item], value: item };
                        });
                        controlItem.placeholder = `请选择${controlItem.label}`;
                        break;
                    }
                    case ThingsModelType.JSON:
                        controlItem.type = FormControlType.TEXT_AREA;
                        controlItem.placeholder = `请输入${controlItem.label}`;
                        controlItem.value = JSON.stringify(controlItem.value);
                        break;
                    case ThingsModelType.PASSWORD:
                        controlItem.type = FormControlType.TEXT;
                        controlItem.inputType = 'password';
                        break;
                    case ThingsModelType.TEXT:
                        controlItem.type = FormControlType.TEXT_AREA;
                        break;
                    case ThingsModelType.STRING:
                    default:
                        controlItem.type = FormControlType.TEXT;
                        break;
                }
                return controlItem;
            });
            this.jtlForm.initFormByControls(controlList, ViewModeType.UPDATE);
        });
    }
    saveAttrClick() {
        // 校验系数
        let validateRatio = true;
        this.clientAttrs.forEach(item => {
            if (!item.ratio || !NUMBER_FLOAT_REGEXP.test(item.ratio)) {
                this.showMessageWarning('系数不能为空且数据类型必须是float');
                validateRatio = false;
            }
            if (this.protocol === ProtocolType.MODBUS && !item.len) {
                this.showMessageWarning('MODBUS协议中长度不能为空');
                throw new Error('BACNET协议中长度不能为空');
            }
        });
        if (!validateRatio) {
            return false;
        }
        // 服务端属性
        const params = _.map(this.ServerAttrs, item => {
            let formValue = this.jtlForm.formModel[item.key];
            const attrType = _.get(item, 'definition.type');
            if ([ThingsModelType.INT, ThingsModelType.FLOAT, ThingsModelType.DOUBLE].indexOf(attrType) > -1) {
                formValue = parseFloat(formValue);
            }
            return {
                entityId: this.entityId,
                key: item.key,
                scope: AttributeType.SERVER,
                value: formValue
            };
        });

        // 客户端属性处理
        if (this.clientAttrs && this.clientAttrs.length > 0) {
            let valueFilter = [];
            if (this.protocol === ProtocolType.MODBUS) {
                valueFilter = _.map(this.clientAttrs, item => {
                    return `${item['address'] || ''},${item['identifier'] || ''},${item['ratio']},${item['len']};`;
                });
            } else if (this.protocol === ProtocolType.BACNET) {
                valueFilter = _.map(this.clientAttrs, item => {
                    return `${item['variablename'] || ''}/${item['address'] || ''},${item['identifier'] || ''},${item['ratio']};`;
                });
            } else {
                valueFilter = _.map(this.clientAttrs, item => {
                    return `${item['attrstring'] || ''},${item['identifier'] || ''},${item['ratio']};`;
                });
            }
            params.push({
                entityId: this.entityId,
                key: 'Param',
                scope: AttributeType.SERVER,
                value: valueFilter.join('')
            });
        }

        DeviceService.saveAttr(params).then(res => {
            this.dialogClose();
            this.$emit('dialogOK');
        });
    }
}
