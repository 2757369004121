var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      staticClass: "form-edit-component-large",
      attrs: { title: "查看数据", visible: _vm.dialogVisible },
      on: { cancel: _vm.dialogClose }
    },
    [
      _c(
        "div",
        {
          staticClass:
            "device-attribute-data-component form-edit-component-large"
        },
        [
          _c(
            "div",
            { staticClass: "page-table-search page-table-search-small" },
            [
              _c("a-date-picker", {
                staticStyle: { width: "180px" },
                model: {
                  value: _vm.startDate,
                  callback: function($$v) {
                    _vm.startDate = $$v
                  },
                  expression: "startDate"
                }
              }),
              _c("a-time-picker", {
                attrs: { format: "HH:mm" },
                model: {
                  value: _vm.startTime,
                  callback: function($$v) {
                    _vm.startTime = $$v
                  },
                  expression: "startTime"
                }
              }),
              _vm._v(" ~ "),
              _c("a-date-picker", {
                staticStyle: { width: "180px" },
                model: {
                  value: _vm.endDate,
                  callback: function($$v) {
                    _vm.endDate = $$v
                  },
                  expression: "endDate"
                }
              }),
              _c("a-time-picker", {
                attrs: { format: "HH:mm" },
                model: {
                  value: _vm.endTime,
                  callback: function($$v) {
                    _vm.endTime = $$v
                  },
                  expression: "endTime"
                }
              }),
              _c(
                "jtl-button",
                {
                  staticClass: "pull-right",
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.getData(false)
                    }
                  }
                },
                [_vm._v("查询")]
              )
            ],
            1
          ),
          _c("a-table", {
            attrs: {
              loading: _vm.listLoading,
              columns: _vm.tableColumns,
              "data-source": _vm.listData,
              pagination: false,
              scroll: { y: 400 },
              "row-key": "ts",
              size: "small"
            }
          })
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "jtl-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  return _vm.getData(true)
                }
              }
            },
            [_vm._v("加载更多数据")]
          ),
          _c("jtl-button", { on: { click: _vm.dialogClose } }, [_vm._v("关闭")])
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }