


























































import { Component, Prop } from 'vue-property-decorator';
import TableComponent from '@/mixins/table-component';
import { ProtocolEntityModel, ProtocolqueryModel } from '@/entity-model/protocol-entity';
import { ProtocolType } from '@/model/enum';
import { protocolModelTypeList } from '@/filter/protocol-var-type';

@Component
export default class DeviceProtocolTableComponent extends TableComponent<ProtocolEntityModel, ProtocolqueryModel> {
    protocolModelTypeList = protocolModelTypeList;
    @Prop()
    clientAttrs: Array<any>;
    @Prop()
    serverAttrs: Array<any>;
    @Prop()
    protocol:ProtocolType;
    @Prop()
    deviceName:string;

    copyData:string = '';

    get CopyData() {
        return this.copyData;
    }
    set CopyData(data: string) {
        const attrListExcel = this.formatDataFromExcel(data);
        if (!this.checkoutExcelData(attrListExcel)) {
            return;
        }
        const filterData = _.filter(attrListExcel, item => _.trim(item[0]).indexOf(_.trim(this.deviceName)) > -1);
        console.log(_.filter(attrListExcel, item => _.trim(item[0]).indexOf(_.trim(this.deviceName)) === -1));
        this.copyData = JSON.stringify(filterData);
        const filterLength = attrListExcel.length - filterData.length;
        if (filterLength > 0) {
            this.showMessageInfo(`已为您过滤掉 ${attrListExcel.length - filterData.length} 个其他设备`);
        }
        this.linkClick();
    }

    get TableColumns() {
        let columns = null;
        switch (this.protocol) {
            case ProtocolType.MODBUS:
                columns = ProtocolEntityModel.getModbusTableColumns();
                break;
            case ProtocolType.BACNET:
                columns = ProtocolEntityModel.getBacnetTableColumns();
                break;
            case ProtocolType.OPCWEB:
                columns = ProtocolEntityModel.getOPCWebTableColumns();
                break;
        }
        return columns;
    }
    created() {
        const serverParam = _.find(this.serverAttrs, item => item.key === 'Param' && ['string', 'text'].indexOf(item.definition['type']) > -1);
        const serverParamValue = _.get(serverParam, 'value');
        if (serverParamValue) {
            const serverParamValueList = _.filter(serverParamValue.split(';'), item => item);
            if (this.protocol === ProtocolType.MODBUS || this.protocol === ProtocolType.OPCWEB) {
                _.forEach(this.clientAttrs, item => {
                    for (let i = 0; i < serverParamValueList.length; i++) {
                        const serverParamValue = serverParamValueList[i].split(',');
                        const serverParamIdentifier = serverParamValue[1];
                        const stringValue = serverParamValue[0];
                        const ratio = serverParamValue[2] || 1;
                        if (item.identifier === serverParamIdentifier) {
                            item.ratio = ratio;
                            if (this.protocol === ProtocolType.OPCWEB) {
                                item.attrstring = stringValue;
                            } else {
                                item.address = stringValue;
                                item.len = serverParamValue[3];
                            }
                            break;
                        }
                    }
                });
            } else {
                _.forEach(this.clientAttrs, item => {
                    for (let i = 0; i < serverParamValueList.length; i++) {
                        const serverParamValue = serverParamValueList[i].split(',');
                        const serverParamIdentifier = serverParamValue[1];
                        const nameAndAddress = serverParamValue[0];
                        if (item.identifier === serverParamIdentifier) {
                            const nameAndAddressArray = nameAndAddress.split('/');
                            item.variablename = _.get(nameAndAddressArray, '[0]');
                            item.address = _.get(nameAndAddressArray, '[1]');
                            item.ratio = _.get(serverParamValue, '[2]') || 1;
                            item.len = _.get(serverParamValue, '[3]');
                            break;
                        }
                    }
                });
            }
        }
    }

    private formatDataFromExcel(data: string) {
        if (data) {
            if (data.indexOf('\n') > -1) {
                let rowListExcel = data.split('\n');
                let attrListExcel = _.map(rowListExcel, item => item.split('	'));
                return attrListExcel;
            } else {
                return JSON.parse(data);
            }
        }
    }

    private checkoutExcelData(excelData): boolean {
        let checkResult = true;
        switch (this.protocol) {
            case ProtocolType.BACNET:
                if (_.get(excelData, '[0].length') !== 4) {
                    this.showMessageWarning('Bacnet协议要复制4列');
                    checkResult = false;
                }
                break;
            case ProtocolType.MODBUS:
            case ProtocolType.OPCWEB:
                if (_.get(excelData, '[0].length') !== 3) {
                    this.showMessageWarning('Modbus\\Opeweb协议要复制3列');
                    checkResult = false;
                }
                break;
        }
        return checkResult;
    }

    linkClick() {
        const attrListExcel = JSON.parse(this.CopyData);
        if (!this.checkoutExcelData(attrListExcel)) {
            return;
        }
        if (this.protocol === ProtocolType.BACNET) {
            _.forEach(this.clientAttrs, item => {
                for (let i = 0; i < attrListExcel.length; i++) {
                    const attrNameExcel = _.trim(attrListExcel[i][1]);
                    const attrName = _.trim(item.name);
                    if (attrNameExcel && attrNameExcel === attrName) {
                        item.variablename = _.kebabCase(attrListExcel[i][2]);
                        item.address = attrListExcel[i][3];
                        break;
                    }
                }
            });
        } else {
            _.forEach(this.clientAttrs, item => {
                for (let i = 0; i < attrListExcel.length; i++) {
                    const attrNameExcel = _.trim(attrListExcel[i][1]);
                    const attrName = _.trim(item.name);
                    if (attrNameExcel && attrNameExcel === attrName) {
                        item.attrstring = attrListExcel[i][2];
                        break;
                    }
                }
            });
        }
    }
}

